import React from 'react'
import Nav from '../../common/Nav';
import '../Home/home.css';
const Bookings = () => {
  return (
    <div className="container-fluid overflow-auto body ">
    <div className=' container-fluid  '>
      <Nav />
     
      <div className='rawTxt'>
        <p> Bookings Coming Soon</p>
      </div>
      </div>
      </div>
  )
}

export default Bookings