// DestinationDetailPage.js
import React from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../../common/Footer';
import Nav from '../../common/Nav';
import Destination from './Destination';

const hotels = [
  {
    name: "Luxury Resort & Spa",
    image: "https://img.freepik.com/premium-photo/luxury-spa-resort-sunset-with-outdoor-thermal-pools-lush-tropical-plants-ideal-relaxation-wellness_416256-13318.jpg", // Replace with actual image URLs
    amenities: ["Free Wi-Fi", "Swimming Pool", "Spa", "Gym"],
    normalPrice: 120,
    memberPrice: 90,
    rating: 4.5,
    reviews: [
      { user: "John D.", comment: "Amazing stay with fantastic amenities!" },
      { user: "Sara K.", comment: "Perfect getaway spot!" },
    ],
  },
  {
    name: "City Center Inn",
    image: "https://cf.bstatic.com/xdata/images/hotel/max1024x768/467536432.webp?k=1984509b7e0bd8cb77f56e63e2abf8841b8bc279c94f6af54e02b8267af635e0&o=",
    amenities: ["Free Wi-Fi", "Complimentary Breakfast", "Fitness Center"],
    normalPrice: 100,
    memberPrice: 75,
    rating: 4.2,
    reviews: [
      { user: "Michael L.", comment: "Excellent location and services." },
      { user: "Emma W.", comment: "Highly recommend this hotel!" },
    ],
  },
  {
    name: "Beachfront Hotel",
    image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLGWKsN8us9ELpJgTiNcak06jBjodcTwbaBg&s",
    amenities: ["Private Beach", "Pool", "Restaurant", "Bar"],
    normalPrice: 150,
    memberPrice: 110,
    rating: 4.8,
    reviews: [
      { user: "Lucas M.", comment: "Loved the private beach experience." },
      { user: "Olivia G.", comment: "Fantastic location and food!" },
    ],
  },
];
const destinations = [
    {
        id: 1,
        name: 'Bali',
        image: 'https://i.pinimg.com/236x/3f/ec/f1/3fecf1bd88fb488d15d76e888cd4b2b7.jpg',
        packageDetailLink: '/packages/bali'
    },
    {
        id: 2,
        name: 'Paris',
        image: 'https://i.pinimg.com/236x/28/f8/10/28f81080bf8c2ba64587008bce395866.jpg',
        packageDetailLink: '/packages/paris'
    },
    {
        id: 3,
        name: 'New York',
        image: 'https://i.pinimg.com/236x/7a/9f/bf/7a9fbfc3c023668b5c5834bf3a382d1e.jpg',
        packageDetailLink: '/packages/new-york'
    },
    {
        id: 4,
        name: 'Tokyo',
        image: 'https://i.pinimg.com/236x/b2/ea/5f/b2ea5f68de982a06816ee5b013e235d8.jpg',
        packageDetailLink: '/packages/tokyo'
    },
];
const placesToVisit = [
  { name: "Old Town Market", image: "https://www.shopkhoj.com/wp-content/uploads/2018/06/Civili-Lines.jpg" },
  { name: "Heritage Museum", image: "https://en-media.thebetterindia.com/uploads/2022/06/Unique-museums-of-India-1656423095.jpg" },
  { name: "City Zoo", image: "https://static.toiimg.com/photo/msid-110569101,width-96,height-65.cms" },
  { name: "Central Park", image: "https://www.easemyshift.com/img/blog/20992.jpg" },
];

const DestinationDetailPage = ( ) => {
    const {destinationId} = useParams();
    // const handleDestination=()=>{
    //         destinationsName = des
    // }
  return (
    <div className=" bg-gray-100">
         <div className='p-6'>
        <Nav />
      {/* Header */}
      <h1 className="text-4xl font-bold text-center  mt-12 mb-6 text-gray-500">{destinationId} - Destination Details</h1>

      {/* Hotels Section */}
      <section className="mb-10">
        <h2 className="text-2xl font-semibold mb-4 text-gray-600">Hotels in {destinationId}</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {hotels.map((hotel, index) => (
            <div key={index} className="bg-white rounded-lg shadow-lg p-4 transform hover:scale-105 transition-transform duration-300">
              <img src={hotel.image} alt={hotel.name} className="w-full h-48 object-cover rounded-md mb-4" />
              <h3 className="text-xl font-semibold mb-2 text-gray-700">{hotel.name}</h3>
              <p className="text-gray-600 mb-4">Amenities: {hotel.amenities.join(", ")}</p>
              <div className="flex justify-between items-center mb-4">
                <span className="text-gray-600">Normal Price:</span>
                <span className="font-bold text-gray-600">${hotel.normalPrice} / night</span>
              </div>
              <div className="flex justify-between items-center mb-4">
                <span className="text-gray-600">Member Price:</span>
                <span className="font-bold text-blue-600">${hotel.memberPrice} / night</span>
              </div>
              <p className="text-yellow-500 mb-4">Rating: {hotel.rating} ⭐</p>
              <button className="mt-4 w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600">Book Now</button>
            </div>
          ))}
        </div>
      </section>

      {/* Places to Visit */}
      <section className="mb-10">
        <h2 className="text-2xl font-semibold mb-4 text-gray-600">Best Places to Visit in {destinationId}</h2>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {placesToVisit.map((place, index) => (
            <div key={index} className="bg-white rounded-lg shadow-md p-4 text-center">
              <img src={place.image} alt={place.name} className="w-full h-40 object-cover rounded-md mb-2" />
              <p className="text-gray-700 font-medium">{place.name}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Customer Reviews */}
      <section className="mb-10">
        <h2 className="text-2xl font-semibold mb-4 text-gray-600">Customer Reviews</h2>
        {hotels.map((hotel, index) => (
          <div key={index} className="mb-6">
            <h3 className="text-xl font-semibold mb-2 text-gray-500">{hotel.name} - Reviews</h3>
            {hotel.reviews.map((review, reviewIndex) => (
              <div key={reviewIndex} className="mb-4 p-4 bg-gray-50 rounded-md">
                <p className="text-gray-700 font-medium">{review.user}</p>
                <p className="text-gray-600">{review.comment}</p>
              </div>
            ))}
          </div>
        ))}
      </section>
     </div>
      <Footer/>
    </div>
  );
};

export default DestinationDetailPage;
