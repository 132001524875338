import React, { useState } from 'react';
import Nav from '../../common/Nav';

const PaymentGateway = () => {
  const [amount, setAmount] = useState(100); // Example total amount
  const [discount, setDiscount] = useState(0);
  const [paymentMode, setPaymentMode] = useState('');

  const handleDiscountChange = (e) => {
    const value = e.target.value;
    setDiscount(value);
  };

  const handlePaymentModeChange = (e) => {
    setPaymentMode(e.target.value);
  };

  const totalAmount = amount - discount;

  return (
      <div className='bg-gray-100  flex flex-col min-h-screen h-screen w-screen max-h-max p-0 m-0'>
          <Nav />
     
    <div className="flex flex-col items-center p-8 bg-gray-100 min-h-screen">
        
      <h1 className="text-2xl font-bold mb-4">Payment Gateway</h1>
      <form className="bg-white shadow-md rounded-lg p-6 w-full max-w-lg">
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Cardholder Name</label>
          <input type="text" className="border rounded w-full py-2 px-3" placeholder="John Doe" required />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Card Number</label>
          <input type="text" className="border rounded w-full py-2 px-3" placeholder="**** **** **** ****" required />
        </div>

        <div className="flex mb-4">
          <div className="flex-1 mr-2">
            <label className="block text-gray-700 mb-2">Expiry Date</label>
            <input type="text" className="border rounded w-full py-2 px-3" placeholder="MM/YY" required />
          </div>
          <div className="flex-1">
            <label className="block text-gray-700 mb-2">CVV</label>
            <input type="text" className="border rounded w-full py-2 px-3" placeholder="***" required />
          </div>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Discount Code</label>
          <input type="text" value={discount} onChange={handleDiscountChange} className="border rounded w-full py-2 px-3" placeholder="Enter code" />
        </div>

        <div className="mb-4">
          <h2 className="text-lg font-semibold">Payment Modes</h2>
          <div className="flex flex-col">
            {['Credit Card', 'Debit Card', 'UPI', 'Net Banking'].map(mode => (
              <label key={mode} className="flex items-center">
                <input
                  type="radio"
                  name="paymentMode"
                  value={mode}
                  onChange={handlePaymentModeChange}
                  className="mr-2"
                />
                {mode}
              </label>
            ))}
          </div>
        </div>

        <div className="mb-4">
          <p className="text-lg font-bold">Total Amount: ₹{totalAmount}</p>
        </div>

        <button type="submit" className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600">
          Pay Now
        </button>
      </form>
    </div>
    </div>
  );
};

export default PaymentGateway;
