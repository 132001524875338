import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Nav from '../../common/Nav';
import '../Home/home.css';


const Flights = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();

  useEffect(() => {
      const fetchImages = async () => {
          setLoading(true);
          try {
              const response = await axios.get(`https://picsum.photos/v2/list?page=2&limit=100`); // Replace with your image API 
             console.log("hhhhhhhhh====>",response.data[0].url)
              setImages((prev) => [...prev, ...response.data]);
              setHasMore(response.data.hasMore);
          } catch (error) {
              console.error("Error fetching images:", error);
          } finally {
              setLoading(false);
          }
      };

      fetchImages();
  }, [page]);

  const lastImageRef = useRef();

  const observerCallback = (entries) => {
      if (entries[0].isIntersecting && hasMore) {
          setPage((prev) => prev + 1);
      }
  };
  let lastImageRefCur = lastImageRef.current
  useEffect(() => {
   
      if (lastImageRefCur) {
          observer.current = new IntersectionObserver(observerCallback);
          observer.current.observe(lastImageRefCur);
      }

      return () => {
          if (lastImageRefCur) {
              observer.current.unobserve(lastImageRefCur);
          }
      };
  }, [lastImageRefCur]);
  return (
    <div className="container-fluid overflow-auto body ">
    <div className=' container-fluid  '>
      <Nav />
     
      <div className='rawTxt'>
        <p> Flights Coming Soon</p>
      </div>
      <div style={{ padding: '20px' }}>
            <h1>Image Gallery</h1>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: '10px' }}>
                {images.map((image, index) => (
                  // console.log("====>>>>>",image.download_url)
                    <img
                        key={image.id}
                        src={image.download_url}
                        alt={image.alt}
                        style={{ width: '100%', height: 'auto' }}
                        ref={index === images.length - 1 ? lastImageRef : null} // Set ref for the last image
                        loading="lazy" // Use lazy loading
                    />
                ))}
            </div>
            {loading && <p>Loading...</p>}
            {!hasMore && <p>No more images to load.</p>}
        </div>
      </div>
      </div>
  )
}

export default Flights