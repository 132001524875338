import React from 'react';
import Nav from './Nav';

const PackageDetail = () => {
    // Use window.location.pathname to get the current path
    const path = window.location.pathname;
    const location = path.split('/').pop(); // Get the last part of the path

    // Example data based on location
    const packageDetails = {
        bali: {
            images: ['https://via.placeholder.com/300', 'https://via.placeholder.com/300'],
            reviews: ['Great experience!', 'A must-visit destination!'],
            bestPlaces: ['Ubud', 'Seminyak'],
            prices: { day: '$200', night: '$150' },
        },
        paris: {
            images: ['https://via.placeholder.com/300', 'https://via.placeholder.com/300'],
            reviews: ['Absolutely beautiful!', 'Loved the culture!'],
            bestPlaces: ['Eiffel Tower', 'Louvre Museum'],
            prices: { day: '$250', night: '$200' },
        },
        // Add more locations as needed
    };

    const details = packageDetails[location.toLowerCase()] || {};

    return (
        <div className="max-w-6xl mx-auto px-4 pb-10">
            <Nav/>
            <h1 className="text-2xl pt-10 font-bold">{location.charAt(0).toUpperCase() + location.slice(1)}</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
                <div>
                    <h2 className="text-xl font-semibold">Images</h2>
                    {details.images?.map((img, index) => (
                        <img key={index} src={img} alt={`Detail ${index}`} className="w-full h-48 object-cover mb-4" />
                    ))}
                </div>
                <div>
                    <h2 className="text-xl font-semibold">Reviews</h2>
                    <ul className="list-disc pl-5">
                        {details.reviews?.map((review, index) => (
                            <li key={index}>{review}</li>
                        ))}
                    </ul>
                    <h2 className="text-xl font-semibold mt-4">Best Places to Visit</h2>
                    <ul className="list-disc pl-5">
                        {details.bestPlaces?.map((place, index) => (
                            <li key={index}>{place}</li>
                        ))}
                    </ul>
                    <h2 className="text-xl font-semibold mt-4">Package Prices</h2>
                    <p>Day: {details.prices?.day}</p>
                    <p>Night: {details.prices?.night}</p>
                </div>
            </div>
        </div>
    );
};

export default PackageDetail;
