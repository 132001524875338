import React, { useState } from 'react'
import Nav from '../../common/Nav'
import '../Home/home.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import restaurantIcon from '../../assets/restaurant.png';
import flightIcon from '../../assets/flight.png';
import hotelIcon from '../../assets/hotel.png';
import customizationIcon from '../../assets/customization.png';
import jaipurIcon from '../../assets/jaipur.webp';
import baliIcon from '../../assets/bali.webp';
import goaIcon from '../../assets/goa.png';
import frame from '../../assets/Frame.png';
import frameModel from '../../assets/Image.png';
import { GooglePlayButton, AppStoreButton, ButtonsContainer, } from "react-mobile-app-button";
import Footer from '../../common/Footer';
import TopBar from '../../common/TopBar';
import LoginSignup from '../Authentication/LoginSignup';
import ImageCards from '../../common/ImageCards';
import CounterCards from '../../common/counterCard';
import Counter from '../../common/counterCard';
import Memberships from '../MemberShip/Memberships';
import DestinationGallery from '../Destination/DestinationGallery';
import logoIcon from '../../assets/Logo.png'




const Home = () => {
  const [activeFAQ, setActiveFAQ] = useState(null);
  const APKUrl =
    "https://play.google.com/store/apps/details?id=host.exp.exponent";
  const iOSUrl = "https://apps.apple.com/us/app/expo-go/id982107779";
  const faqs = [
    { question: "What are the membership tiers?", answer: "We offer Silver, Gold, and Platinum tiers with increasing benefits and exclusive perks." },
    { question: "How long is the membership valid?", answer: "The membership is valid for one year and can be renewed annually." },
    { question: "Can I upgrade my membership?", answer: "Yes, you can upgrade your membership at any time to access more benefits." }
  ];
  const toggleFAQ = (index) => {
    setActiveFAQ(activeFAQ === index ? null : index);
  };
  return (
    <div className=" bg-gray-100  flex flex-col min-h-screen h-screen w-screen max-h-max p-0 m-0">
      <div className='flex-grow relative pb-20'>
        {/* <TopBar /> */}
        <img className=' absolute w-1/2  h-2/6 lg:h-1/2 right-0 p-0 m-0' src={frame} />

        <div className='  p-0 h-1/2 md:h-dvh w-full 	sm:mb-40 mb-0	  '>
          <div className='p-6'>
            <Nav />
          </div>
          <div className='absolute p-2 pt-4 mb:mt-6 right-4 left-2 md:right-6 md:left-4'>
            <img src={frameModel} className='h-2/5 w-2/4 float-right' />
            <div className='mt-2 pt-2  pb-4 mb-4'>
              <p className=" ml-5 text-start text-xl md:text-7xl font-bold text-black font-serif">Travel,<br></br>Enjoy and Discover your<br></br>world  your way.</p>
              <p className='ml-2 text-start '>Discover your next getaway with us! Whether you're dreaming of serene beaches, scenic mountains, or thrilling urban adventures, our travel portal offers a curated selection of experiences to inspire every type of traveler. Dive into exclusive deals, explore tailored holiday packages, and find the perfect stay at destinations across the globe. Let us help you plan an unforgettable journey filled with relaxation, excitement, and discovery. Your dream vacation is just a few clicks away!</p>
            </div>
            <div className='hidden md:flex  md:pt-18'>
              <ButtonsContainer>
                <GooglePlayButton
                  url={APKUrl}
                  theme={"dark"}
                  className={"custom-style"}
                />
                <AppStoreButton
                  url={iOSUrl}
                  theme={"dark"}
                  className={"custom-style"}
                />
              </ButtonsContainer>
            </div>

            <div className='md:pt-40 pt-0 flex justify-center'>
              <span className='py-10 md:py-14 text-base md:text-2xl font-semibold inline-block align-middle'> Dream, Explore, Discover with  </span><img className='bg-gray-100 h-24 md:h-32 w-24 md:w-32  mx-2 md:mx-10 inline-block align-middle' src={logoIcon}/>
            </div>

          </div>
        </div>
        <div className='relative h-screen w-full sm:mt-40 mt-0 top-64 md:top-0'>
          {/* <ImageCards/> */}
          <Memberships isHome={true} />
          <div className='bg-gray-100 flex flex-col md:flex-row justify-center items-center  gap-4 py-20 px-10'>
            <div className='basis-1/2	'>
              <h1 className='pb-10'>What We Offer</h1>
              <ul className='list-disc flex flex-col items-start'>
                <li className=' flex-row'>
                  <h5 className='flex flex-row'>Tailored Itineraries:</h5>
                  <p>
                    Our travel specialists work closely with you to design customized itineraries that suit your interests, budget, and schedule.
                  </p>
                </li>
                <li>
                  <h5 className='flex flex-row'>Expert Advice:</h5>
                  <p>
                    With in-depth knowledge of global destinations, we provide insights and tips to enhance your travel experience                  </p>
                </li>
                <li>
                  <h5 className='flex flex-row'>Seamless Booking: </h5>
                  <p>
                    From flights and accommodations to tours and activities, we handle all the details, ensuring a stress-free booking process.                  </p>
                </li>
                <li>
                  <h5 className='flex flex-row'> 24/7 Support:</h5>
                  <p>
                    Your peace of mind is our priority. Our team is available around the clock to assist you during your travels, ensuring you have a safe and enjoyable journey.                  </p>
                </li>
              </ul>
            </div>
            <div className='basis-1/2	'>
              <h1 className='pb-10'>Our Values</h1>
              <ul className='list-disc '>
                <li className=''>
                  <h5 className='flex flex-row'>Customer-Centric:</h5> <p className='font-normal text-base'>
                    We prioritize your needs and preferences, ensuring every trip is personalized and memorable.                  </p>

                </li>
                <li>
                  <h5 className='flex flex-row'>Integrity:</h5>
                  <p>
                    We are committed to transparency and honesty, building trust with our clients through ethical practices.
                  </p>
                </li>
                <li>
                  <h5 className='flex flex-row'>Seamless Booking: </h5>
                  <p>
                    From flights and accommodations to tours and activities, we handle all the details, ensuring a stress-free booking process.
                  </p>
                </li>
                <li>
                  <h5 className='flex flex-row'>Sustainability:</h5>
                  <p>
                    We believe in responsible travel and strive to promote eco-friendly practices that benefit local communities and preserve the environment.                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className=''>
            <div className=' p-12'>
              <div className='flex flex-col md:flex-row justify-center items-center  gap-4 pb-20 pt-20 h-full md:h-96 w-full'>
                <div className="mb-2 basis-1/2">
                  <h2 className="text-2xl font-semibold text-gray-800 mb-4">Our Mission</h2>
                  <p className="text-gray-600">
                    Our mission is to inspire and empower travelers by providing exceptional service, expert guidance, and personalized itineraries tailored to your unique preferences. Whether you're dreaming of a relaxing beach getaway, an adventurous mountain trek, or a cultural journey through vibrant cities, we are here to make your travel dreams a reality.
                  </p>
                </div>
                <div className='h-full w-4/5 md:h-96 md:w-96	shadow-2xl 	mb-12'>
                  <img className='w-full h-full 	shadow-2xl ' src='https://i.pinimg.com/236x/05/b1/0b/05b10bc874f4fd995da171ce3a5be09f.jpg'></img>
                </div>
              </div>
              <div className='flex flex-col md:flex-row justify-center items-center gap-4 pt-20 h-96 w-full'>
                <div className=' h-full w-4/5 md:h-96	md:w-96	shadow-2xl '>
                  <img className='w-full h-full 	shadow-2xl' src='https://i.pinimg.com/236x/31/08/2b/31082b936ec946630c93cafe0ba27799.jpg'></img>
                </div>
                <div className="mb-10 basis-1/2">
                  <h2 className="text-2xl font-semibold text-gray-800  mb-4">Join Us on Your Next Adventure!</h2>
                  <p className="text-gray-600 mb-20">
                    Whether you’re a seasoned traveler or planning your first trip, [Your Travel Agency Name] is here to guide you every step of the way. Let us help you discover new horizons and experience the beauty of the world.

                  </p>
                </div>

              </div>
            </div>
          </div>
          <section className=''>
            <DestinationGallery />
          </section>

          <div className='bg-white-100 flex flex-col   items-center justify-start p-12 m-12'>
            <h1 className=' hidden md:flex flex-col items-center  mb-0 pb-6'>Our Stats!!  </h1>
            <div className="flex flex-col md:flex-row items-center justify-center gap-12 p-6 pt-0 bg-white-100">
              <h1 className='md:hidden'>Our Stats!!  </h1>
              <Counter label="Trips" finalCount={2250} duration={2000} />
              <Counter label="Members" finalCount={8000} duration={4000} />
              <Counter label="Travelers" finalCount={10000} duration={100} />
            </div>
          </div>
          {/* FAQs Section */}
          <section className="py-12 bg-gray-100">
            <div className="container mx-auto px-4">
              <h2 className="text-2xl font-bold text-center mb-8">Frequently Asked Questions</h2>
              <div className="space-y-4">
                {faqs.map((faq, index) => (
                  <div key={index} className="bg-white p-6 rounded-lg shadow-md cursor-pointer" onClick={() => toggleFAQ(index)}>
                    <div className="flex justify-between items-center">
                      <h4 className="text-lg font-bold">{faq.question}</h4>
                      <span>{activeFAQ === index ? '-' : '+'}</span>
                    </div>
                    {activeFAQ === index && <p className="mt-4 text-gray-600">{faq.answer}</p>}
                  </div>
                ))}
              </div>
            </div>
          </section>
          {/* <p className=" ml-5 text-start text-xl md:text-7xl font-DM text-black font-mono">Find Popular<br></br>Destinations.</p>

        <h1 className='txtHeading'>We Offers Best Services</h1>

        <div class="row row-cols-1 row-cols-md-5 g-8 ms-5 me-auto " >
          <div class="col w-20 shadow-lg p-3 mb-5 bg-body rounded ms-5">
            <div class="card h-100">
              <img src={restaurantIcon} class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title">Restaurants</h5>
                <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
              </div>
            </div>
          </div>
          <div class="col w-20 shadow-lg p-3 mb-5 bg-body rounded ms-5">
            <div class="card h-100 ">
              <img src={flightIcon} class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title">Best Flights</h5>
                <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content.</p>
              </div>
            </div>
          </div>

          <div class="col w-20 shadow-lg p-3 mb-5 bg-body rounded ms-5">
            <div class="card h-100">
              <img src={hotelIcon} class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title">Best Hotels</h5>
                <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
              </div>
            </div>
          </div>
          <div class="col w-20 shadow-lg p-3 mb-5 bg-body rounded ms-5">
            <div class="card h-100 ">
              <img src={customizationIcon} class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title">Customizations</h5>
                <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='section-3'>
        <h6 className='txtSmallHeading'>Top Selling</h6>
        <h1 className='txtHeading'>Top Destinations</h1>
        <div class="card-group">
          <div class="card ms-5 shadow-lg p-3 mb-5 bg-body rounded">
            <img src={baliIcon} class="card-img-top h-75" alt="..." />
            <div class="card-body">
              <h5 class="card-title">Bali , Indonasia</h5>
              <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
              <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
            </div>
          </div>
          <div class="card ms-5 shadow-lg p-3 mb-5 bg-body rounded">
            <img src={jaipurIcon} class="card-img-top h-75" alt="..." />
            <div class="card-body">
              <h5 class="card-title">Jaipur , Rajasthan</h5>
              <p class="card-text">This card has supporting text below as a natural lead-in to additional content.</p>
              <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
            </div>
          </div>
          <div class="card ms-5 me-5 shadow-lg p-3 mb-5 bg-body rounded">
            <img src={goaIcon} class="card-img-top h-75" alt="..." />
            <div class="card-body">
              <h5 class="card-title">Goa , India</h5>
              <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</p>
              <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">

          <div className="col ">

            <div className="row ">
              <p className='float-start mb-0  txtSmallHeading'>Easy and Fast</p><br /><br />
              <p className='float-start pt-0 txtHeading '>Book Your Next Trip <br /> In 3 Easy Steps</p><br /><br />
              <div className="col-2">
                <img className='section3Img' src={restaurantIcon} />
              </div>
              <div className="col-9">
                <p className='float-start mb-0 txtSmallHeading'>Choose Destination</p>
                <p className='float-start pt-0'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                  labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco .</p>
              </div>
            </div>
            <div className="row ">
              <div className="col-2">
                <img className='section3Img' src={restaurantIcon} />
              </div>
              <div className="col-9">
                <p className='float-start mb-0 txtSmallHeading'>Make Payment </p>
                <p className='float-start pt-0'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                  labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco .</p>
              </div>
            </div>
            <div className="row">
              <div className="col-2">
                <img className='section3Img' src={restaurantIcon} />
              </div>
              <div className="col-9">
                <p className='float-start mb-0 txtSmallHeading'>Reach Airport on Selected Date</p>
                <p className='float-start pt-0'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                  labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco .</p>
              </div>
            </div>

          </div>
          <div class="col">
            2 of 2
          </div>
        </div>
        <div className='row my-5'>
          <div className='col'>
            <p className='txtSmallHeading float-start mb-0'> Testimonials </p><br/><br/>
            <p className='txtHeading float-start pt-0 mb-5'> What People Say <br /> About Us.</p><br/><br/>
            
            
            </div>
            <div className='col'>

            </div>
          </div>
          <div className='row'> 
            <div id="carouselExampleDark" class="carousel carousel-dark slide pt-5" data-bs-ride="carousel">
              <div class="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
              </div>
           </div>
            </div>
            <div className='frmBottom'>
              <p className='btmTxt'>Subscribe to get information , Latest news and other <br/> interesting offers about Pravasa!</p> <br/><br/>
              <input type='email' className='subscribeInput' placeholder='Your Email'></input>
              <button className='subscribeBtn'> Subscribe </button>

            </div>*/}
          <Footer />
        </div>
        {/* <Footer /> */}

      </div>

    </div>

  )
}
export default Home