import React from 'react';
import Nav from '../../common/Nav';
import Footer from '../../common/Footer';

const AboutUs = () => {
    return (
        <div className="bg-gray-100 ">
            <div className='p-6'>
                <Nav />
            </div>
            <div className="  mx-auto px-4 pt-10 ">
                <div className='bg-white flex flex-col md:flex-row items-center gap-4 py-10 h-full w-full shadow-lg'>
                    <div className='basis-4/6  p-12'>
                        <h1 className="text-3xl md:text-4xl text-center font-bold text-gray-800">About Us</h1>
                        <h4 className='text-xl md:text-2xl text-center font-semibold text-gray-800'>Welcome to Pravasa!</h4>
                        <p>At Pravasa, we believe that travel is not just about reaching a destination; it’s about creating unforgettable experiences and making lasting memories. With over 10 years of expertise in the travel industry, our dedicated team is passionate about helping you explore the world, one adventure at a time.</p>
                    </div>
                    <div className=' h-full w-4/5 md:h-96 md:w-80  '>
                        <img className='w-full h-full' src='https://i.pinimg.com/236x/85/a7/c8/85a7c8115d55fe259403a66945367f00.jpg'></img>
                    </div>
                </div>
                {/* Hero Section */}
                {/* <div className="text-center mb-12">
                    <h1 className="text-3xl md:text-4xl font-bold text-gray-800">About Us</h1>
                    <p className="mt-4 text-gray-600">
                        Discover your next adventure with us! We are committed to providing unforgettable travel experiences.
                    </p>
                </div> */}

                {/* Mission Section */}
                <div className='  py-20 '>
                    <div className='flex flex-col md:flex-row justify-center items-center  gap-4 pb-20 h-full md:h-96 w-full'>
                        <div className="mb-10 basis-1/2">
                            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Our Mission</h2>
                            <p className="text-gray-600">
                                Our mission is to inspire and empower travelers by providing exceptional service, expert guidance, and personalized itineraries tailored to your unique preferences. Whether you're dreaming of a relaxing beach getaway, an adventurous mountain trek, or a cultural journey through vibrant cities, we are here to make your travel dreams a reality.
                            </p>
                        </div>
                        <div className='h-full w-4/5 md:h-96 md:w-96	'>
                            <img className='w-full h-full' src='https://i.pinimg.com/236x/05/b1/0b/05b10bc874f4fd995da171ce3a5be09f.jpg'></img>
                        </div>
                    </div>
                    <div className='flex flex-col md:flex-row justify-center items-center gap-4 pt-20 h-96 w-full'>
                        <div className='h-full w-4/5 md:h-96	md:w-96	 '>
                            <img className='w-full h-full' src='https://i.pinimg.com/236x/05/b1/0b/05b10bc874f4fd995da171ce3a5be09f.jpg'></img>
                        </div>
                        <div className="mb-10 basis-1/2">
                            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Join Us on Your Next Adventure!</h2>
                            <p className="text-gray-600">
                                Whether you’re a seasoned traveler or planning your first trip, [Your Travel Agency Name] is here to guide you every step of the way. Let us help you discover new horizons and experience the beauty of the world.

                            </p>
                        </div>

                    </div>
                </div>


                {/* Team Section */}
                <div className="mb-10">
                    <h2 className="text-2xl font-semibold text-gray-800 mb-4">Meet Our Team</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        {teamMembers.map((member, index) => (
                            <div key={index} className="bg-white p-6 rounded-lg shadow-lg text-center">
                                <img src={member.image} alt={member.name} className="w-24 h-24 mx-auto rounded-full mb-4" />
                                <h3 className="font-semibold text-lg text-gray-800">{member.name}</h3>
                                <p className="text-gray-500">{member.position}</p>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Testimonials Section */}
                <div className=' pb-4 w-full'>
                    <h2 className="text-2xl font-semibold text-gray-800 mb-4">What Our Clients Say</h2>
                    <div className="space-y-4">
                        {testimonials.map((testimonial, index) => (
                            <blockquote key={index} className="bg-white p-6 rounded-lg shadow-lg">
                                <p className="text-gray-600 italic">"{testimonial.quote}"</p>
                                <footer className="mt-2 w-full text-gray-800 font-semibold">
                                    — {testimonial.author}
                                </footer>
                            </blockquote>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

const teamMembers = [
    {
        name: 'Alice Johnson',
        position: 'Travel Consultant',
        image: 'https://via.placeholder.com/150',
    },
    {
        name: 'Bob Smith',
        position: 'Adventure Specialist',
        image: 'https://via.placeholder.com/150',
    },
    {
        name: 'Cathy Lee',
        position: 'Customer Service Manager',
        image: 'https://via.placeholder.com/150',
    },
];

const testimonials = [
    {
        quote: 'The trip was amazing! Everything was perfectly planned.',
        author: 'John Doe',
    },
    {
        quote: 'I had the best experience with this travel agency. Highly recommended!',
        author: 'Sarah Williams',
    },
    {
        quote: 'Great service and very knowledgeable staff.',
        author: 'Michael Brown',
    },
];

export default AboutUs;
