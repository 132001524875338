// src/components/Hotels.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import hotelImage from '../../assets/udaipur.jpeg'
import Nav from '../../common/Nav';
import Footer from '../../common/Footer';

const Hotels = () => {
  const [searchInput, setSearchInput] = useState('');
  const [searchCategory, setSearchCategory] = useState('location');
  const [hotels, setHotels] = useState([]);
  const [deals, setDeals] = useState([
    { id: 1, title: 'Flash Sale: 50% Off', description: 'Limited time offer on beach resorts' },
    { id: 2, title: 'Early Bird Special', description: 'Book 30 days in advance and save 30%' },
  ]);
  const navigate = useNavigate();
  const [accessToken, setAccessToken] =useState('nsan4JPD480fZv9aMGSAXGFIl4UR');
  const [searchKey, setSearchKey] = useState('')

  // Fetch hotel data from an API
  // useEffect(() => {
  //   const fetchHotels = async () => {
  //     try {
  //       const response = await axios.get('API_ENDPOINT/hotels');
  //       setHotels(response.data);
  //     } catch (error) {
  //       console.error("Error fetching hotels:", error);
  //     }
  //   };
  //   fetchHotels();
  // }, []);

  // Use useEffect to call fetchToken every 30 minutes
  useEffect(() => {
    // Fetch the token immediately when the component mounts
    fetchToken();

    // Set up the interval to fetch the token every 30 minutes
    const interval = setInterval(() => {
      fetchToken();
    }, 30 * 60 * 1000); // 30 minutes in milliseconds
    console.log("hotel data :::::>>>", hotels);
    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
    
  }, []);
  const fetchToken = async () => {
    try {
      const response = await axios.post(`https://test.api.amadeus.com/v1/security/oauth2/token`, {
          client_id: '8VTCJfFc1rIARvNhQfOZEibWfyU9Jlrr',
          client_secret: 'kq9GCFCcrGLkQ9Ko',
          grant_type: 'client_credentials',
      },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      console.log('token ::::>',response.data.access_token);
      setAccessToken(response.data.access_token);
    } catch (error) {
      console.error('Error fetching hotels:', error);
    }
  };

  // Search hotels based on input
  const handleSearchInput =(e)=>{
    const input = e.target.value;
    setSearchInput(input);

    // Get the first three letters and convert to uppercase
    if (input.length >= 3) {
      const letters = input.substring(0, 3).toUpperCase();
      setSearchKey(letters);
    } else {
      setSearchKey(''); // Clear if input is less than 3 characters
    }
  }
  const handleSearch = async () => {
    try {
      const response = await axios.get(`https://test.api.amadeus.com/v1/reference-data/locations/hotels/by-city`, {
        params: {
          cityCode: searchKey, // e.g., "LON" for London
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log('hotel data ::::>',response.data.data);
      setHotels(response.data.data);
    } catch (error) {
      console.error('Error fetching hotels:', error);
      alert(`Sorry we don't have listed hotels in ${searchInput} City.`)
    }
  };

  // Navigate to the hotel details page
  const handleHotelClick = (id) => {
    navigate(`/hotel/${id}`,{state:accessToken});
  };

  return (
    <div>
      <div className='p-6'>
        <Nav/>
      </div>
    <div className="p-4 max-w-6xl mx-auto">
      
      {/* Search Bar */}
      <div className="flex justify-center items-center py-4 bg-blue-500 text-black rounded-lg">
        <input
          type="text"
          placeholder="Search by location, hotel name, or type..."
          value={searchInput}
          onChange={(e) => handleSearchInput(e)}
          className="px-4 py-2 rounded-md focus:outline-none w-full sm:w-1/2"
        />
        <select
          value={searchCategory}
          onChange={(e) => setSearchCategory(e.target.value)}
          className="ml-2 px-2 py-2 rounded-md bg-white text-gray-700"
        >
          <option value="location">Location</option>
          <option value="name">Hotel Name</option>
          <option value="type">Property Type</option>
        </select>
        <button
          onClick={handleSearch}
          className="ml-2 px-4 py-2 bg-green-500 rounded-md hover:bg-green-600 transition"
        >
          Search
        </button>
      </div>

      {/* Deals Section */}
      <div className="my-8 p-4 bg-gray-100 rounded-md shadow-sm">
        <h2 className="text-2xl font-semibold text-center mb-4">Best Deals & Offers</h2>
        <div className="flex flex-wrap justify-center space-x-4">
          {deals.map((deal) => (
            <div
              key={deal.id}
              className="w-72 p-4 border bg-white rounded-md shadow-md hover:shadow-lg transition-transform duration-300 transform hover:scale-105"
            >
              <h3 className="text-lg font-semibold text-blue-500">{deal.title}</h3>
              <p className="text-gray-700 mt-2">{deal.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Hotel Listing */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {hotels.map((hotel) => (
          <div
            key={hotel.id}
            className="cursor-pointer border rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-shadow duration-300"
            onClick={() => handleHotelClick(hotel.hotelId)}
          >
            <img src={hotelImage} alt={hotel.name} className="w-full h-48 object-cover" />
            <div className="p-4">
              <h3 className="text-xl font-semibold">{hotel.name}</h3>
              <p className="text-gray-700">{hotel.hotelId}</p>
              <div className="flex justify-between items-center mt-2">
                <span className="text-lg font-bold text-green-600">${400}</span>
                <span className="bg-yellow-300 text-sm rounded-full px-2 py-1">{4.3}★</span>
              </div>
              <button className="mt-4 w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition">
                Book Now
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default Hotels;


// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';import Deals from '../../common/Deals'
// import Footer from '../../common/Footer'
// import Nav from '../../common/Nav'
// import SearchBar from '../../common/SearchBar'

// const Hotels = () => {
//   const [hotels, setHotels] = useState([]);
//   const navigate = useNavigate();

//   // Fetch hotel data (replace 'API_ENDPOINT' with actual endpoint)
//   useEffect(() => {
//     const fetchHotels = async () => {
//       try {
//         const response = await axios.get('API_ENDPOINT/hotels');
//         setHotels(response.data);
//       } catch (error) {
//         console.error("Error fetching hotels:", error);
//       }
//     };
//     fetchHotels();
//   }, []);

//   // Handle navigation to hotel details
//   const handleHotelClick = (id) => {
//     navigate(`/hotel/${id}`);
//   };
//   return (
//     <div>
//       <div>
//         <Nav/>
//       </div>
//       <div>
//         <SearchBar/>
//         <Deals/>
//         <div className="p-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
//       {hotels.map((hotel) => (
//         <div
//           key={hotel.id}
//           className="cursor-pointer border rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-shadow duration-300"
//           onClick={() => handleHotelClick(hotel.id)}
//         >
//           <img src={hotel.image} alt={hotel.name} className="w-full h-48 object-cover" />
//           <div className="p-4">
//             <h3 className="text-xl font-semibold">{hotel.name}</h3>
//             <p className="text-gray-700">{hotel.location}</p>
//             <div className="flex justify-between items-center mt-2">
//               <span className="text-lg font-bold text-green-600">${hotel.price}</span>
//               <span className="bg-yellow-300 text-sm rounded-full px-2 py-1">{hotel.rating}★</span>
//             </div>
//             <button className="mt-4 w-full bg-blue-500 text-white py-2 rounded-md">
//               Book Now
//             </button>
//           </div>
//         </div>
//       ))}
//     </div>
//       </div>
//       <Footer/>
//     </div>
//   )
// }

// export default Hotels