// // src/firebase.js
// import { initializeApp } from 'firebase/app';
// import { getAuth } from 'firebase/auth';
// import { getAnalytics } from "firebase/analytics";
import { initializeApp } from 'firebase/app';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCsJoPBkpDpbD1SLe3IoOXq7WmvFu6EPzE",
  authDomain: "pravasa-4f2c0.firebaseapp.com",
  projectId: "pravasa-4f2c0",
  storageBucket: "pravasa-4f2c0.appspot.com",
  messagingSenderId: "719592839160",
  appId: "1:719592839160:web:f0c3eb3b6dd3144430680d",
  measurementId: "G-DRM8W0XTNP"
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// const auth = getAuth(app);

// // auth.settings.appVerificationDisabledForTesting = true;

// export { auth };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
auth.settings.appVerificationDisabledForTesting = true; // Use only in development!

export { auth, RecaptchaVerifier, signInWithPhoneNumber };