// src/Counter.js
import React, { useEffect, useState } from 'react';

const Counter = ({ label, finalCount, duration }) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        const interval = duration / finalCount;

        const counterInterval = setInterval(() => {
            setCount(prevCount => {
                if (prevCount < finalCount) {
                    return prevCount + 1;
                } else {
                    clearInterval(counterInterval);
                    return prevCount;
                }
            });
        }, interval);

        return () => clearInterval(counterInterval);
    }, [finalCount, duration]);

    return (
        <div className="bg-white rounded-lg flex flex-col items-center justify-center">
            <div className=" basis-1/3 w-64 h-64 text-4xl font-bold text-orange-600 shadow-md p-4 rounded-lg">
            {count}
                <p className='font-normal text-2xl pt-10 text-gray-600'> {label}</p>
            </div>
        </div>
    );
};

export default Counter;
