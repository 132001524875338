import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios';
import qs from 'qs';
import Footer from '../../common/Footer'
import ImageCards from '../../common/ImageCards'
import Nav from '../../common/Nav'
import { FaCheckCircle, FaRegClock, FaMapMarkedAlt, FaPhoneAlt, FaStar } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';




const MembershipDetailPage = () => {
    const [activeFAQ, setActiveFAQ] = useState(null);
    const [isForm, setIsForm] = useState(false);
    const [activePlan, setActivePlan] = useState('Basic');
    const [isOpen, setIsOpen] = useState(false);
    const formRef = useRef(null);
    const [formData, setFormData] = useState({
        applicantName: '',
        coApplicantName: '',
        email: '',
        mobile: '',
        selectedPlan: '',
        selectedPeriod: '',
        address: '',
    });
    const benefits = [
        {
            title: "Flexible Point-Based Booking",
            description: "Enjoy a flexible, point-based booking system that allows you to plan vacations as per your needs.",
            icon: <FaRegClock className="text-blue-500 text-4xl" />,
        },
        {
            title: "Easy Upgrades & Top-ups",
            description: "Upgrade your membership or top-up points at any time to get access to more destinations.",
            icon: <FaStar className="text-yellow-500 text-4xl" />,
        },
        {
            title: "150+ Resorts Across India",
            description: "Access over 100+ luxurious resorts spread across scenic locations in India.",
            icon: <FaMapMarkedAlt className="text-green-500 text-4xl" />,
        },
        {
            title: "24/7 Dedicated Helpdesk",
            description: "Our helpdesk is available 24x7 to assist you with bookings, upgrades, and more.",
            icon: <FaPhoneAlt className="text-red-500 text-4xl" />,
        },
    ];
    const steps = [
        {
            title: "Choose a Plan",
            description: "Select from our range of flexible membership plans that suit your needs.",
            icon: "https://via.placeholder.com/100", // You can replace this with real icons or images.
        },
        {
            title: "Add Your Personal Details",
            description: "Provide your personal details to complete your membership profile.",
            icon: "https://via.placeholder.com/100",
        },
        {
            title: "Get Help from Our Helpdesk",
            description: "Our support team is always available to assist you with any queries.",
            icon: "https://via.placeholder.com/100",
        },
        {
            title: "Select Best Offers & Packages",
            description: "Choose from a variety of travel packages and exclusive deals for members.",
            icon: "https://via.placeholder.com/100",
        },
        {
            title: "Complete Payment",
            description: "Complete your membership payment through our secure payment gateway.",
            icon: <FaStar className="text-yellow-500 text-4xl" />,
        },
        {
            title: "Enjoy Ultimate Experience",
            description: "Enjoy your stay at our luxurious resorts and taste the best cuisine.",
            icon: "https://via.placeholder.com/100",
        },
    ];

    const faqs = [
        { question: "What are the membership tiers?", answer: "We offer Silver, Gold, and Platinum tiers with increasing benefits and exclusive perks." },
        { question: "How long is the membership valid?", answer: "The membership is valid for one year and can be renewed annually." },
        { question: "Can I upgrade my membership?", answer: "Yes, you can upgrade your membership at any time to access more benefits." }
    ];
    const plans = [
        {
            name: "Basic",
            duration: [{ years: "5 Years", price: "$1000" }, { years: "10 Years", price: "$1500" }, { years: "15 Years", price: "$2000" }, { years: "25 Years", price: "$2500" }],
            propertyType: "3 ⭐ to 4 ⭐",
            capacity: "2 adults and 2 kids (under 5 years)",
            annualMaintenance: "$100/year",
        },
        {
            name: "Premium",
            duration: [{ years: "5 Years", price: "$2000" }, { years: "10 Years", price: "$3000" }, { years: "15 Years", price: "$3500" }, { years: "25 Years", price: "$4500" }],
            propertyType: "4⭐ to 5⭐",
            capacity: "2 adults and 2 kids (under 5 years)",
            annualMaintenance: "$200/year",
        },
        {
            name: "Elite",
            duration: [{ years: "10 Years", price: "$3000" }, { years: "10 Years", price: "$4500" }, { years: "15 Years", price: "$5000" }, { years: "25 Years", price: "$6000" }],
            propertyType: "4-star to 5-star",
            capacity: "4 adults and 2 kids (under 5 years)",
            annualMaintenance: "$300/year",
        },
        {
            name: "Corporate",
            duration: [{ years: "10 Years", price: "$5000" }, { years: "10 Years", price: "$7500" }, { years: "15 Years", price: "$10000" }, { years: "25 Years", price: "$12000" }],
            propertyType: "4-star to 5-star",
            capacity: "Up to 50 adults",
            annualMaintenance: "$500/year",
        },
    ];

    const toggleFAQ = (index) => {
        setActiveFAQ(activeFAQ === index ? null : index);
    };
    // const openPopup = () => {
    //     setIsOpen(true);
    //   };
    const closePopup = () => {
        setIsOpen(false);
      };
    const handleBecomeMemberbtn = () => {
        setIsOpen(true);
    }
    const handleClickOutside = (event) => {
        if (formRef.current && !formRef.current.contains(event.target)) {
          closePopup();
        }
      };
    useEffect(() => {
        // Add event listener to detect clicks outside of the form
        document.addEventListener("mousedown", handleClickOutside);
    
        // Remove event listener on cleanup
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, []);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };
    const data = qs.stringify(formData);
    const handleSubmit = async (e) => {
        e.preventDefault();
        // navigate("/payment")
        console.log("afhai:::::>>>>", data)
        const googleFormURL = 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSfWqNxCIx_YCkfb3EyGanUC54OmloQfRUDD4lzROcLRC8cCFA/formResponse'; // Replace with your Google Form's POST URL

        const formParams = new URLSearchParams();
        formParams.append('entry.1471499584', formData.applicantName);  // Replace entry.1234567890 with your Name field's unique entry ID
         formParams.append('entry.1882965974', formData.coApplicantName);
        formParams.append('entry.263604595', formData.email);  // Replace entry.0987654321 with your Email field's unique entry ID
        formParams.append('entry.34203619', formData.mobile);
        formParams.append('entry.29698345', formData.selectedPlan);
         formParams.append('entry.337770971', formData.selectedPeriod);
         formParams.append('entry.2022138794', formData.address);



        try {
            const response = await axios.post(googleFormURL,formParams)({
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
              });
            console.log('Data submitted successfully:', response.data);
            alert('Data submitted successfully!');
        } catch (error) {
            console.error('Error submitting data:', error);
             alert('Error submitting data. Please try again.');
            // alert('Data submitted successfully!');
        }
    }
    return (
        <div className=' bg-gray-100'>
            <div className='p-6'>
                <Nav />
            </div>
            <div>
                {/* <img 
         src={'https://image.slidesdocs.com/responsive-images/background/travel-poster-powerpoint-background_e25b43252e__960_540.jpg'}
         className='w-full h-96'
          /> */}
                <section className="py-16 bg-gray-100">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold text-center mb-12">Benefits of Becoming a Member</h2>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
                            {benefits.map((benefit, index) => (
                                <div
                                    key={index}
                                    className="p-6 bg-white rounded-lg shadow-lg text-center transition-transform transform hover:scale-105"
                                >
                                    <div className="mb-4">
                                        {benefit.icon}
                                    </div>
                                    <h3 className="text-xl font-semibold mb-2">{benefit.title}</h3>
                                    <p className="text-gray-600">{benefit.description}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                <section className='destination'>
                    <div className='p-2 m-2'>
                        <h4 className='text-3xl sm:text-4xl md:text-5xl font-bold  text-center  font-bold text-cyan-400'>Destinations</h4>
                        <p className='text-lg font-bold sm:text-xl mt-4 text-gray-300 text-center text-gray-800'>Choose from over 100+ resorts spread across a variety of terrains worldwide.</p>
                        <ImageCards />
                    </div>
                </section>
                <section>
                    <div className="p-6 bg-gray-100 min-h-screen flex flex-col items-center">
                        <h1 className="text-4xl font-bold mb-6 text-gray-800">Membership Plans</h1>

                        <div className="w-full max-w-2xl space-y-4">
                            {plans.map((plan, index) => (
                                <motion.div
                                    key={index}
                                    onMouseEnter={() => setActivePlan(plan.name)}
                                    onMouseLeave={() => setActivePlan(null)}
                                    className="border border-gray-300 rounded-lg overflow-hidden shadow-md bg-white"
                                >
                                    <div className="p-4 bg-gray-200 cursor-pointer">
                                        <h2 className="text-xl font-semibold text-gray-700">{plan.name}</h2>
                                    </div>

                                    <AnimatePresence>
                                        {activePlan === plan.name && (
                                            <motion.div
                                                initial={{ height: 0, opacity: 0 }}
                                                animate={{ height: 'auto', opacity: 1 }}
                                                exit={{ height: 0, opacity: 0 }}
                                                transition={{ duration: 0.4, ease: "easeInOut" }}
                                                className="p-4 text-gray-800"
                                            >
                                                <div className="mb-4">
                                                    <h3 className="font-semibold text-lg">Plan Duration and Pricing:</h3>
                                                    <ul className="list-disc list-inside">
                                                        {plan.duration.map((item, idx) => (
                                                            <li key={idx}>
                                                                {item.years}  - {item.price}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>

                                                <div className="mb-4">
                                                    <h3 className="font-semibold text-lg">Property Type:</h3>
                                                    <p>{plan.propertyType}</p>
                                                </div>

                                                <div className="mb-4">
                                                    <h3 className="font-semibold text-lg">Occupancy Capacity:</h3>
                                                    <p>{plan.capacity}</p>
                                                </div>

                                                <div className="mb-4">
                                                    <h3 className="font-semibold text-lg">Annual Maintenance Cost:</h3>
                                                    <p>{plan.annualMaintenance}</p>
                                                </div>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </motion.div>
                            ))}
                        </div>
                    </div>
                </section>

                <section className="bg-gray-100 py-16">
                    <div className=" mx-auto px-4">
                        <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold text-center  font-bold text-cyan-400">
                            How to Become a Member
                        </h2>
                        <p className='text-lg font-bold sm:text-xl  mb-12 mt-4 text-gray-300 text-center text-gray-800'>You are just a few steps away from experiencing blissful holidays.</p>
                        <div className="flex flex-col md:flex-row items-center justify-between space-y-8 md:space-y-0 md:space-x-8">
                            {steps.map((step, index) => (
                                <div key={index} className="flex-1 text-center p-2 md:p-16 bg-white shadow-lg rounded-lg transition-transform transform hover:scale-105"
                                >
                                    <img
                                        src={step.icon}
                                        alt={`${step.title} icon`}
                                        className="w-16 h-16 mx-auto mb-4"
                                    />
                                    <h3 className="text-xl font-bold mb-2">{step.title}</h3>
                                    <p className="text-gray-600">{step.description}</p>
                                    {index < steps.length && (
                                        <div className="p-2 bg-gradient-to-r from-blue-500 to-green-500 text-white shadow-lg rounded-lg"
                                        >
                                            <FaCheckCircle className="text-green-500 mx-auto text-3xl" />
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                        <div className="text-center mt-12">
                            <button onClick={handleBecomeMemberbtn} className="bg-blue-500 text-white px-8 py-3 text-lg font-bold rounded-lg hover:bg-green-500 transition">
                                BECOME A MEMBER
                            </button>
                        </div>

                        {/* <div className='flex justify-center pt-12'>
        <button className='bg-orange-500 p-2 rounded font-sans text-lg font-bold'>BECOME A MEMBER</button>

        </div> */}
                    </div>
                </section>
            </div>
            {/* User detail form for membership */}
            <section>
                {isOpen ?
                    <div >
                        {/* <CustomerDetailForm isForm={isForm} isDetailPage={true}/> */}
                        <div className="bg-cover bg-center w-full">
                            <div  className={`fixed bg-gray-300  inset-0  bg-opacity-50 flex items-center justify-center p-4 z-50`}>
                                <div ref={formRef} className="backdrop-blur-md rounded-lg p-6 max-w-md w-full">
                                    <h2 className="text-xl font-semibold mb-4 text-gray-500">Please fill your details here</h2>

                                    <form onSubmit={handleSubmit} className="mt-4">
                                        <input
                                            type="text"
                                            name="applicantName"
                                            placeholder="Applicant Name"
                                            value={formData.applicantName}
                                            onChange={handleChange}
                                            required
                                            className=" rounded mb-2 w-full w-full px-4 py-2 border-b bg-transparent focus:outline-none focus:border-b-blue-500"
                                        />
                                        <input
                                            type="text"
                                            name="coApplicantName"
                                            placeholder="Co-Applicant Name"
                                            value={formData.coApplicantName}
                                            onChange={handleChange}
                                            required
                                            className=" rounded mb-2 w-full w-full px-4 py-2 border-b bg-transparent focus:outline-none focus:border-b-blue-500"
                                        />
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            required
                                            className="rounded mb-2 w-full w-full px-4 py-2 border-b bg-transparent focus:outline-none focus:border-b-blue-500"
                                        />

                                        <input
                                            type="number"
                                            name="mobile"
                                            placeholder="Mobile"
                                            value={formData.mobile}
                                            onChange={handleChange}
                                            required
                                            className=" rounded mb-2 w-full w-full px-4 py-2 border-b bg-transparent focus:outline-none focus:border-b-blue-500"
                                        />
                                        <select
                                            name="selectedPlan"
                                            value={formData.selectedPlan}
                                            onChange={handleChange}
                                            className=" rounded mb-4 w-full w-full px-4 py-2 border-b bg-transparent focus:outline-none focus:border-b-blue-500"
                                            required
                                        >
                                            <option value="" disabled>Select Membership Plan</option>
                                            {plans.map((plan) => (
                                                <option key={plan.name} value={plan.name}>{plan.name}</option>
                                            ))}
                                        </select>
                                        <select
                                            name="selectedPeriod"
                                            value={formData.selectedPeriod}
                                            onChange={handleChange}
                                            className=" rounded mb-4 w-full w-full px-4 py-2 border-b bg-transparent focus:outline-none focus:border-b-blue-500"
                                            required
                                        >
                                            <option value="" disabled>Select Membership Period</option>
                                            {plans[0].duration.map((plan) => (
                                                <option key={plan.years} value={plan.years}>{plan.years}</option>


                                            ))}
                                        </select>
                                        <input
                                            type="text"
                                            name="address"
                                            placeholder="Address"
                                            value={formData.address}
                                            onChange={handleChange}
                                            required
                                            className=" rounded mb-2 w-full w-full px-4 py-2 border-b bg-transparent focus:outline-none focus:border-b-blue-500"
                                        />
                                        <button type="submit" className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 rounded">
                                            Submit
                                        </button>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>

                    : <div></div>}
            </section>
            <Footer />
        </div>
    )
}

export default MembershipDetailPage