// src/components/HotelDetails.js
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import Nav from '../../common/Nav';
import Footer from '../../common/Footer';

const HotelDetails = () => {
  const { id } = useParams();
  const [hotel, setHotel] = useState(null);
  const location = useLocation();
  const accessToken = location.state
  const path = window.location.pathname;
  const hotelId = path.split('/').pop();

  useEffect(() => {
    const fetchHotel = async () => {
      try {
        const response = await axios.get(`https://test.api.amadeus.com/v1/reference-data/locations/hotels/by-hotels`, {
          params: {
            hotelIds: hotelId, // e.g., "LON" for London
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        console.log('hotel data ::::>',response.data.data);
        setHotel(response.data.data);
      } catch (error) {
        console.error('Error fetching hotels:', error);
      }
    };
    fetchHotel();
  }, [id]);

  if (!hotel) return <p>Loading...</p>;

  return (
    <div>
      <div className='p-6'>
        <Nav/>
      </div>
   
    <div className="p-4">

      <div className="max-w-3xl mx-auto">
        {hotel.map((item)=>(
           <div className="max-w-3xl mx-auto">
           <h2 className="text-3xl font-bold mb-2">{item.name}</h2>
           <p className="text-gray-700 mb-4">{item.address.countryCode}</p>
           <img src={'https://cf.bstatic.com/xdata/images/hotel/max1024x768/467536432.webp?k=1984509b7e0bd8cb77f56e63e2abf8841b8bc279c94f6af54e02b8267af635e0&o='} alt={hotel.name} className="w-full h-64 object-cover rounded-md mb-4" />
           <p className="text-gray-800 mb-4">Experience unmatched luxury and comfort at {item.name}, located in the heart of Jaipur. With breathtaking views, elegant interiors, and world-class amenities, our hotel is perfect for both business and leisure travelers. Enjoy spacious rooms equipped with high-speed Wi-Fi, plush bedding, and modern decor that invites relaxation after a day of exploring or meetings.

Indulge in exquisite dining at our on-site restaurants, offering a range of cuisines crafted by top chefs, or unwind at our rooftop bar with panoramic city views. Guests can rejuvenate at our full-service spa, dive into our sparkling pool, or keep up with fitness goals at our well-equipped gym.

Conveniently located near [local attractions, shopping, or business districts], {item.name} offers easy access to the best Jaipur has to offer. Our dedicated staff is committed to making your stay exceptional, with personalized service to ensure all your needs are met. Whether you're here for a romantic getaway, a family vacation, or a corporate event, {item.name} promises an unforgettable experience tailored to you.</p>
           <div className="flex items-center justify-between">
             <span className="text-2xl font-semibold text-green-600">${450}</span>
             <button className="bg-blue-500 text-white px-4 py-2 rounded-md">
               Confirm Booking
             </button>
           </div>
           </div>
        ))}
        {/* <h2 className="text-3xl font-bold mb-2">{hotel.name}</h2>
        <p className="text-gray-700 mb-4">{hotel.location}</p>
        <img src={'https://cf.bstatic.com/xdata/images/hotel/max1024x768/467536432.webp?k=1984509b7e0bd8cb77f56e63e2abf8841b8bc279c94f6af54e02b8267af635e0&o='} alt={hotel.name} className="w-full h-64 object-cover rounded-md mb-4" />
        <p className="text-gray-800 mb-4">{hotel.description}</p>
        <div className="flex items-center justify-between">
          <span className="text-2xl font-semibold text-green-600">${hotel.price}</span>
          <button className="bg-blue-500 text-white px-4 py-2 rounded-md">
            Confirm Booking
          </button>
        </div> */}
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default HotelDetails;
