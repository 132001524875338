import React,{useState} from 'react'
import Footer from '../../common/Footer'
import Nav from '../../common/Nav'
import Carousel from './MembershipCarousel'
import Memberships from './Memberships'
import hotelIcon from '../../assets/hotel.png'
import dummyIcon from '../../assets/udaipur.jpeg'
import CustomerDetailForm from '../../common/CustomerDetailForm'


const MembershipDashboard = () => {
  const testimonials = [
    {
        quote: 'The trip was amazing! Everything was perfectly planned.',
        author: 'John Doe',
    },
    {
        quote: 'I had the best experience with this travel agency. Highly recommended!',
        author: 'Sarah Williams',
    },
    {
        quote: 'Great service and very knowledgeable staff.',
        author: 'Michael Brown',
    },
];
const [activeFAQ, setActiveFAQ] = useState(null);

const faqs = [
  { question: "What are the membership tiers?", answer: "We offer Silver, Gold, and Platinum tiers with increasing benefits and exclusive perks." },
  { question: "How long is the membership valid?", answer: "The membership is valid for one year and can be renewed annually." },
  { question: "Can I upgrade my membership?", answer: "Yes, you can upgrade your membership at any time to access more benefits." }
];

const toggleFAQ = (index) => {
  setActiveFAQ(activeFAQ === index ? null : index);
};

  return (
    <div className="bg-gray-100 flex flex-col min-h-screen h-screen w-screen max-h-max p-0 m-0 ">
       <div className='p-6'>
       <Nav/>
        </div> 
            {/* <Carousel/> */}
            {/* <div className='flex flex-col bg-transparent justify-center my-10 md:mx-40 md:px-20'>
            <h1 className='bg-transparent text-sky-700 drop-shadow-lg  w-fit p-2  shadow-xl shadow-blue-500/50 '>Membership Benifits of Pravasa </h1>
            <p className='font-semibold p-4 text-wrap'>With access to 140+ awe-inspiring resorts in India and abroad, the world opens itself upto you. Filled with over 2000 enriching experiences and joyous moments. Unwind in luxurious floating cottages, tree houses, cabins, tents, ice igloos and pool villas. Your membership offers you all this and more.</p>
            <div className='grid md:grid-cols-3 gap-2 justify-center w-full h-full '>
              <div className='flex flex-row justify-center item-center w-48 md:h-48 p-2 m-2 rounded-md'>
                <img
                src={dummyIcon}
                className='basis-1/2 h-full w-full'
                />
                <h3 className=' font-semibold text-xl font-sans text-wrap h-full w-full p-2 m-2'>Access 150+ resorts in india</h3>
              </div>
              <div className='flex flex-row justify-center item-center w-48 h-48 p-2 m-2 rounded-md'>
                <img
                src={dummyIcon}
                className='basis-1/2 h-full w-full'
                />
                <h3 className='basis-1/2 font-semibold text-xl font-sans text-wrap h-full w-full p-2 m-2'>Access 150+ resorts in india</h3>
              </div>
              <div className='flex flex-row justify-center item-center w-48 h-48 p-2  m-2 rounded-md'>
                <img
                src={dummyIcon}
                className='basis-1/2 h-full w-full'
                />
                <h3 className='basis-1/2 font-semibold text-xl font-sans text-wrap p-2 m-2'>Access 150+ resorts in india</h3>
              </div>
              <div className='flex flex-row justify-center item-center w-48 h-48 p-2 m-2 rounded-md'>
                <img
                src={dummyIcon}
                className='basis-1/2 h-full w-full'
                />
                <h3 className=' font-semibold text-xl font-sans text-wrap h-full w-full p-2 m-2'>Access 150+ resorts in india</h3>
              </div>
              <div className='flex flex-row justify-center item-center w-48 h-48 p-2 m-2 rounded-md'>
                <img
                src={dummyIcon}
                className='basis-1/2 h-full w-full'
                />
                <h3 className='basis-1/2 font-semibold text-xl font-sans text-wrap h-full w-full p-2 m-2'>Access 150+ resorts in india</h3>
              </div>
              <div className='flex flex-row justify-center item-center w-48 h-48 p-2  m-2 rounded-md'>
                <img
                src={dummyIcon}
                className='basis-1/2 h-full w-full'
                />
                <h3 className='basis-1/2 font-semibold text-xl font-sans text-wrap p-2 m-2'>Access 150+ resorts in india</h3>
              </div>
            </div>
            </div> */}
           
           
           
            {/* <div className="bg-[url('https://png.pngtree.com/thumb_back/fw800/background/20231225/pngtree-a-blurred-summer-background-of-a-wooden-floor-terrace-with-a-image_13870555.png')] flex flex-col md:flex-row justify-center  item-center">
              <h1 className=' backdrop-blur-md md:basis-1/2  text-center font-extrabold text-5xl font-sans pt-4 md:m-2'>Holiday with India’s Most Popular Resort Chain</h1>
              <div className='flex flex-row  backdrop-blur-md '>
              <div className=' flex flex-col justify-center md:p-4 md:m-4 item-center md:w-64 md:h-64 shadow-lg'>
                <img 
                  src={hotelIcon}
                  className='md:w-24 md:h-24'
                />
                <p className='font-extrabold text-6xl font-serif text-rose-500'>150+</p>
                <p className='font-bold text-xl font-sans'> HOTELS</p>
              </div>
              <div className='flex flex-col justify-center p-4 item-center md:w-64 md:h-64 shadow-lg' >
                <img
                src={hotelIcon}
                className='md:w-24 md:h-24'
                />
                <p className='font-extrabold text-6xl font-serif text-red-500'> 1500+</p>
                <p className='font-bold text-xl font-sans text-black-500'> RESORTS</p>
              </div>
              </div>
              
              
            </div> */}
            {/* <div className='flex flex-col justify-center item-center md:p-40 '>
                    <h2 className="text-2xl font-semibold text-gray-800 mb-4">What Our Clients Say</h2>
                    <div className="space-y-4">
                        {testimonials.map((testimonial, index) => (
                            <blockquote key={index} className="bg-white p-6 rounded-lg shadow-lg">
                                <p className="text-gray-600 italic">"{testimonial.quote}"</p>
                                <footer className="mt-2 text-gray-800 font-semibold">
                                    — {testimonial.author}
                                </footer>
                            </blockquote>
                        ))}
                    </div>
                </div> */}
                <div className="membership-page">
      {/* Hero Section */}
      <section className="bg-cover bg-center h-64 sm:h-80 md:h-[400px] text-white flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-3xl backdrop-blur-md rounded-lg sm:text-4xl md:text-5xl font-bold text-gray-100">Exclusive Club Membership</h1>
          <p className="text-lg backdrop-blur-md rounded-lg sm:text-xl mt-4 text-gray-300">Join today and enjoy exclusive benefits!</p>
        </div>
      </section>

      {/* Membership Plans */}
      <section className="py-12 bg-gray-50">
        <div className="container mx-auto px-4">
          {/* <h2 className="text-2xl font-bold text-center mb-8">Choose Your Membership Plan</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
            
            <div className="p-6 bg-white shadow-md rounded-lg text-center">
              <h3 className="text-xl font-bold mb-4">Silver Plan</h3>
              <p className="text-gray-600 mb-6">Basic plan with access to select destinations.</p>
              <p className="text-2xl font-bold mb-4">$199/year</p>
              <button className="bg-blue-600 text-white px-6 py-2 rounded">Join Now</button>
            </div>
          
            <div className="p-6 bg-white shadow-md rounded-lg text-center">
              <h3 className="text-xl font-bold mb-4">Gold Plan</h3>
              <p className="text-gray-600 mb-6">Access to more destinations and exclusive perks.</p>
              <p className="text-2xl font-bold mb-4">$299/year</p>
              <button className="bg-blue-600 text-white px-6 py-2 rounded">Join Now</button>
            </div>
            
            <div className="p-6 bg-white shadow-md rounded-lg text-center">
              <h3 className="text-xl font-bold mb-4">Platinum Plan</h3>
              <p className="text-gray-600 mb-6">All-inclusive access with premium benefits.</p>
              <p className="text-2xl font-bold mb-4">$499/year</p>
              <button className="bg-blue-600 text-white px-6 py-2 rounded">Join Now</button>
            </div>
          </div> */}
          <Memberships />
        </div>
      </section>

      {/* Benefits Section */}
      <section className="py-12">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl font-bold text-center mb-8">Membership Benefits</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
            {/* Benefit 1 */}
            <div className="p-6 bg-white shadow-md rounded-lg text-center">
              <h4 className="text-xl font-bold mb-4">Exclusive Destinations</h4>
              <p className="text-gray-600">Access to members-only vacation spots worldwide.</p>
            </div>
            {/* Benefit 2 */}
            <div className="p-6 bg-white shadow-md rounded-lg text-center">
              <h4 className="text-xl font-bold mb-4">Priority Booking</h4>
              <p className="text-gray-600">Enjoy priority booking and better availability.</p>
            </div>
            {/* Benefit 3 */}
            <div className="p-6 bg-white shadow-md rounded-lg text-center">
              <h4 className="text-xl font-bold mb-4">Special Discounts</h4>
              <p className="text-gray-600">Get exclusive discounts on hotels, activities, and more.</p>
            </div>
          </div>
        </div>
      </section>

      {/* FAQs Section */}
      <section className="py-12 bg-gray-100">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl font-bold text-center mb-8">Frequently Asked Questions</h2>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-md cursor-pointer" onClick={() => toggleFAQ(index)}>
                <div className="flex justify-between items-center">
                  <h4 className="text-lg font-bold">{faq.question}</h4>
                  <span>{activeFAQ === index ? '-' : '+'}</span>
                </div>
                {activeFAQ === index && <p className="mt-4 text-gray-600">{faq.answer}</p>}
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="py-12">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl font-bold text-center mb-8">What Our Members Say</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
            {/* Testimonial 1 */}
            <div className="p-6 bg-white shadow-md rounded-lg text-center">
              <p className="text-gray-600">"The best decision we've made! The membership has been worth every penny."</p>
              <p className="text-lg font-bold mt-4">- John Doe</p>
            </div>
            {/* Testimonial 2 */}
            <div className="p-6 bg-white shadow-md rounded-lg text-center">
              <p className="text-gray-600">"Amazing vacation spots and the customer service is top-notch."</p>
              <p className="text-lg font-bold mt-4">- Jane Smith</p>
            </div>
            {/* Testimonial 3 */}
            <div className="p-6 bg-white shadow-md rounded-lg text-center">
              <p className="text-gray-600">"I love the flexibility and variety of destinations we can choose from!"</p>
              <p className="text-lg font-bold mt-4">- Mark Wilson</p>
            </div>
          </div>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className="py-12 pb-12 mb-12 bg-blue-600 text-white text-center">
        <h2 className="text-2xl font-bold mb-4">Ready to Join?</h2>
        <p className="text-lg mb-6">Become a member today and unlock exclusive vacation benefits!</p>
        <button className="bg-white text-blue-600 px-6 py-2 rounded">Join Now</button>
      </section>
    </div>
            <CustomerDetailForm/>
        <Footer/>
    </div>
  )
}

export default MembershipDashboard