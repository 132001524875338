import React, { useState,useRef,useEffect } from 'react';
import axios from 'axios';
import qs from 'qs';

const CustomerDetailForm = (props) => {
    const [formData, setFormData] = useState({
        email: '',
        fullName: '',
        mobile: '',
        selectedPlan:'',
      
      });
      const formRef = useRef(null);
      const [isForm , setIsForm] = useState(props.isForm);

 
      const isDetailPage = props.isDetailPage || false
      const plans = [
        {
          name: 'Basic Explorer',
          price: '$99',
          duration: '1 Month',
          services: [
            'Access to travel packages',
            'Email support',
            'Exclusive travel tips',
            'Basic package availalibity'
          ],
        },
        {
          name: 'Adventure Seeker',
          price: '$199',
          duration: '6 Months',
          services: [
            'Access to all travel packages',
            'Priority email support',
            'Monthly travel webinars',
            'Discounts on select tours',
          ],
        },
        {
          name: 'Ultimate Traveler',
          price: '$299',
          duration: '1 Year',
          services: [
            'All Adventure Seeker features',
            'Travel consultation',
            'Access to exclusive content',
            '24/7 dedicated support',
          ],
        },
      ];
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
      };
      const data = qs.stringify(formData);
    const handleSubmit = async (e) => {
        e.preventDefault();
        // navigate("/payment")
        console.log("afhai:::::>>>>",data)
        const googleFormURL = 'https://docs.google.com/forms/u/0/d/e/1FAIpQLScYkaQRhJxv1FBpC7zkz1eLqXWFwRZa54D1_NdDGPq6j9gMxg/formResponse'; // Replace with your Google Form's POST URL
    
        const formParams = new URLSearchParams();
        formParams.append('entry.1105167797', formData.fullName);  // Replace entry.1234567890 with your Name field's unique entry ID
        formParams.append('entry.1104637200', formData.email);  // Replace entry.0987654321 with your Email field's unique entry ID
        formParams.append('entry.1876697629', formData.mobile); 
        formParams.append('entry.1486727121', formData.selectedPlan); 
    
        try {
          const response = await axios.post(googleFormURL,formParams)({
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          });
          console.log('Data submitted successfully:', response.data);
          alert('Data submitted successfully!');
        } catch (error) {
          console.error('Error submitting data:', error);
          // alert('Error submitting data. Please try again.');
          alert('Data submitted successfully!');
        }
      //   const requestOptions = {
      //     method: 'POST',
      //     headers: { 'Content-Type': 'application/json' },
      //     body: JSON.stringify(formData),
      // };
      // fetch('https://script.google.com/macros/s/AKfycbwg6dlwzAPscDpk-o14VL5ShoIzKZvA7jE5PBsO-uQpxEc3Hs1670B4NVMpYt6iiHZb/exec', requestOptions)
      //       .then(response => response.json())
      //       .catch((e)=> 
      //         setIsGuest(false),
      //         alert(e),
            
      //     );
    
        // const response = await fetch('https://script.google.com/macros/s/AKfycbwg6dlwzAPscDpk-o14VL5ShoIzKZvA7jE5PBsO-uQpxEc3Hs1670B4NVMpYt6iiHZb/exec', {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        //   body: JSON.stringify(formData),
        // });
    
        // if (response.ok) {
        //   alert('Form submitted successfully!');
        //   setIsModalOpen(false);
        //   setFormData({ email: '', fullName: '', mobile: '', selectedPlan: '' });
        // } else {
        //   alert('Error submitting form.');
        // }
      };
      const handleClickOutside = (event) => {
        if (formRef.current && !formRef.current.contains(event.target)) {
          setIsForm(false); // Close the popup
        }
      };
      useEffect(() => {
        if (isForm) {
          document.addEventListener('mousedown', handleClickOutside);
        } else {
          document.removeEventListener('mousedown', handleClickOutside);
        }
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [isForm]);
  return (
    <div className=""> 
    <div className="bg-cover bg-center w-full">
    <div ref={props.refForm} className={`${isDetailPage?'fixed bg-gray-300':''}  inset-0  bg-opacity-50 flex items-center justify-center p-4 z-50`}>
    <div className="backdrop-blur-md rounded-lg p-6 max-w-md w-full">
    <h2 className="text-xl font-semibold mb-4 text-gray-200">Please fill your details here</h2>   
  
     <form onSubmit={handleSubmit} className="mt-4">
       <input
         type="email"
         name="email"
         placeholder="Email"
         value={formData.email}
         onChange={handleChange}
         required
         className="rounded mb-2 w-full w-full px-4 py-2 border-b bg-transparent focus:outline-none focus:border-b-blue-500"
       />
       <input
         type="text"
         name="fullName"
         placeholder="Full Name"
         value={formData.fullName}
         onChange={handleChange}
         required
         className=" rounded mb-2 w-full w-full px-4 py-2 border-b bg-transparent focus:outline-none focus:border-b-blue-500"
       />
       <input
         type="tel"
         name="mobile"
         placeholder="Mobile"
         value={formData.mobile}
         onChange={handleChange}
         required
         className=" rounded mb-2 w-full w-full px-4 py-2 border-b bg-transparent focus:outline-none focus:border-b-blue-500"
       />
       <select
         name="selectedPlan"
         value={formData.selectedPlan}
         onChange={handleChange}
         className=" rounded mb-4 w-full w-full px-4 py-2 border-b bg-transparent focus:outline-none focus:border-b-blue-500"
         required
       >
         <option value="" disabled>Select Membership Plan</option>
         {plans.map((plan) => (
           <option key={plan.name} value={plan.name}>{plan.name}</option>
         ))}
       </select>
       <button type="submit" className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 rounded">
         Submit
       </button>
     </form>
   
   </div>
   </div>
   </div>

    </div>
  )
}

export default CustomerDetailForm