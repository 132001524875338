
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import Memberships from './components/MemberShip/Memberships';
import Flights from './components/Flights/Flights';
import Destination from './components/Destination/Destination';
import Bookings from './components/Bookings/Bookings';
import Home from './components/Home/Home';
import Hotels from './components/Hotels/Hotels';
import HotelDetail from './components/Hotels/HotelDetail';
import LoginSignup from './components/Authentication/LoginSignup';
import TopBar from './common/TopBar';
import AboutUs from './components/AboutUs/AboutUs';
import BlogPage from './components/Blog/BlogPage';
import PackageDetail from './common/PackageDetail';
import PaymentGateway from './components/Payment/PaymentGateway';
import MembershipDashboard from './components/MemberShip/MembershipDashboard';
import MembershipDetailPage from './components/MemberShip/MembershipDetailPage';
import DestinationDetailPage from './components/Destination/DestinationDetailPage';
import AuthPage from './components/Authentication/AuthPage';
import OTPAuthentication from './components/Authentication/OTPAuthentication';

function App() {
  return (
    <Router>
        <div className="App">
          <Routes>
            <Route exact path='/' element={<Home/>}></Route>
            <Route exact path='/destination' element={<Destination/>}></Route>
            <Route exact path='/booking' element={<Bookings/>}></Route>
            <Route exact path='/flight' element={<Flights/>}></Route>
            <Route exact path='/hotel' element={<Hotels/>}></Route>
            <Route exact path='/membership' element={<MembershipDashboard/>}></Route>
            <Route exact path='/hotel/:Id' element={<HotelDetail/>}></Route>
            <Route exact path='/login' element={<OTPAuthentication/>}></Route>
            <Route exact path='/aboutUs' element={<AboutUs/>}></Route>
            <Route exact path='/blog' element={<BlogPage/>}></Route>
            <Route path="/packages/:location" element={<PackageDetail />} />
            <Route exact path='/payment' element={<PaymentGateway/>} />
            <Route exact path='/memberComponent' element={<Memberships/>} />
            <Route exact path='/membershipDetailPage' element={<MembershipDetailPage/>} />
            <Route path="/destination/:destinationId" element={<DestinationDetailPage />} />


          </Routes>
      
      </div>
    </Router>
    
  );
}

export default App;
