import React from 'react';
import { useNavigate } from 'react-router-dom';

const destinations = [
    {
        id: 1,
        name: 'Bali',
        image: 'https://i.pinimg.com/236x/3f/ec/f1/3fecf1bd88fb488d15d76e888cd4b2b7.jpg',
        packageDetailLink: '/packages/bali'
    },
    {
        id: 2,
        name: 'Paris',
        image: 'https://i.pinimg.com/236x/28/f8/10/28f81080bf8c2ba64587008bce395866.jpg',
        packageDetailLink: '/packages/paris'
    },
    {
        id: 3,
        name: 'New York',
        image: 'https://i.pinimg.com/236x/7a/9f/bf/7a9fbfc3c023668b5c5834bf3a382d1e.jpg',
        packageDetailLink: '/packages/new-york'
    },
    {
        id: 4,
        name: 'Tokyo',
        image: 'https://i.pinimg.com/236x/b2/ea/5f/b2ea5f68de982a06816ee5b013e235d8.jpg',
        packageDetailLink: '/packages/tokyo'
    },
];

const ImageCards = () => {
    const navigate = useNavigate()
    const handleCardClick = (link) => {
        navigate(`/destination/${link}`)
        // window.destination.href = link; // Direct navigation without router
    };

    return (
        <div className="max-w-6xl mx-auto px-4 py-10">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
                {destinations.map((destination) => (
                    <div 
                        key={destination.id} 
                        className="relative cursor-pointer overflow-hidden rounded-lg shadow-lg transition-transform transform hover:scale-105"
                        onClick={() => handleCardClick(destination.name)}
                    >
                        <img 
                            src={destination.image} 
                            alt={destination.name} 
                            className="w-full h-full object-cover" 
                        />
                        <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-2">
                            <h3 className="text-lg font-semibold">{destination.name}</h3>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ImageCards;
