import React from 'react';
import Nav from '../../common/Nav';

const BlogPage = () => {
    const blogPosts = [
        {
            title: 'Top 10 Destinations to Visit in 2024',
            excerpt: 'Discover the most breathtaking places you must see this year!',
            author: 'Alice Johnson',
            date: 'March 12, 2024',
            image: 'https://via.placeholder.com/400',
            link: '#'
        },
        {
            title: 'Travel Tips for First-Time Travelers',
            excerpt: 'Essential tips to make your first travel experience unforgettable.',
            author: 'Bob Smith',
            date: 'February 28, 2024',
            image: 'https://via.placeholder.com/400',
            link: '#'
        },
        {
            title: 'A Guide to Sustainable Travel',
            excerpt: 'Learn how to travel responsibly while enjoying your adventures.',
            author: 'Cathy Lee',
            date: 'January 15, 2024',
            image: 'https://via.placeholder.com/400',
            link: '#'
        },
        // Add more posts as needed
    ];
    const videoSamples = [
        {
            title: 'Exploring the Alps',
            videoUrl: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
            description: 'A breathtaking journey through the majestic Alps.',
        },
        {
            title: 'Adventures in Bali',
            videoUrl: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
            description: 'Experience the vibrant culture and stunning landscapes of Bali.',
        },
    ];

    return (
        <div className="bg-gray-100 pb-10">
            <div className='p-6'>
                <Nav />
            </div>

            <div className="max-w-6xl mx-auto pt-10 px-4">
                <h1 className="text-3xl md:text-4xl font-bold text-gray-800 text-center mb-8">Our Travel Blog</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {blogPosts.map((post, index) => (
                        <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden">
                            <img src={post.image} alt={post.title} className="w-full h-48 object-cover" />
                            <div className="p-4">
                                <h2 className="text-xl font-semibold text-gray-800 mb-2">
                                    <a href={post.link} className="hover:text-blue-600">{post.title}</a>
                                </h2>
                                <p className="text-gray-600 mb-2">{post.excerpt}</p>
                                <p className="text-gray-500 text-sm">
                                    By {post.author} | {post.date}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
                {/* Video Samples Section */}
                <h2 className="text-2xl font-semibold text-gray-800 py-10 mb-4">Video Samples</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
                    {videoSamples.map((video, index) => (
                        <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden">
                            <div className="relative pb-56">
                                <iframe
                                    src={video.videoUrl}
                                    title={video.title}
                                    className="absolute top-0 left-0 w-full h-full rounded-lg"
                                    style={{ border: 'none' }}
                                    allowFullScreen
                                />
                            </div>
                            <div className="p-4">
                                <h3 className="text-lg font-semibold text-gray-800 mb-2">{video.title}</h3>
                                <p className="text-gray-600">{video.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default BlogPage;
